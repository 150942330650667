
import {Component, defineComponent, ref} from 'vue';
import NavTab, {NavTabItem} from '@/components/common/NavTab.vue';
import Decks from '@/components/academy/Decks.vue';
import Media from '@/models/Media';
import {Portal} from 'portal-vue';
import {Resources} from '@/models/Resource';
import Scripts from '@/components/academy/Scripts.vue';
import Templates from '@/components/academy/Templates.vue';
import VideoPlayer from '@/components/common/plyr/VideoPlayer.vue';
import get from 'lodash/get';
import {useRoute} from 'vue-router';

interface IndexTabComponent {
    key: 'all' | 'templates' | 'scripts' | 'decks';
    component: Component;
}

export default defineComponent({
    components: {
        VideoPlayer,
        Decks,
        NavTab,
        Portal,
        Scripts,
        Templates,
    },
    setup() {
        const route = useRoute();

        let viewKey = get(
            route,
            'query.view',
            'all',
        ).toString();

        viewKey = ['scripts', 'decks', 'templates'].includes(viewKey)
            ? viewKey
            : 'all';

        return {
            activeView: ref<IndexTabComponent['key']>(viewKey),
            components: [
                {
                    key: 'scripts',
                    component: Scripts,
                },
                {
                    key: 'decks',
                    component: Decks,
                },
                {
                    key: 'templates',
                    component: Templates,
                },
            ] as IndexTabComponent[],
            introductionResources: ref<Resources>(new Resources([], {
                filters: {
                    /*
                     * 13 is the id of the 'Featured' tag, later-on the API will
                     * implement a filter to search on tag name instead.
                     */
                    'tags.id': 13,
                    mimeType: 'video/mp4',
                },
                perPage: 1,
            })),
            showIntroVideo: ref<boolean>(false),
        };
    },
    computed: {
        tabs(): NavTabItem[] {
            const tabs = [
                {
                    key: 'all',
                },
                ...this.components,
            ];

            return tabs.map((tab: Record<string, any>) => ({
                label: this.$t(`academy.${tab.key}.nav`),
                value: tab.key,
                active: tab.key === this.activeView,
            }));
        },
        introductionVideo(): Media | undefined {
            const resource = this.introductionResources.first();

            return resource?.media.firstWhere('collectionName', 'files');
        },
        introductionVideoThumbnail(): Media | undefined {
            const resource = this.introductionResources.first();

            return resource?.media.firstWhere('collectionName', 'thumbnail');
        },
    },
    watch: {
        activeView() {
            this.showIntroVideo = false;
        },
    },
    created() {
        this.introductionResources.fetch();
    },
    methods: {
        componentVisible(key: string): boolean {
            return this.activeView === key || this.activeView === 'all';
        },
        setActiveView(view: IndexTabComponent['key']) {
            this.activeView = view;

            this.$router.replace({
                name: this.$route.name!,
                query: {
                    view,
                },
            });
        },
    },
});
