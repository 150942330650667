import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mt-6 mb-2" }
const _hoisted_2 = {
  key: 0,
  class: "mb-4 text-muted"
}
const _hoisted_3 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageOverlay = _resolveComponent("ImageOverlay")!
  const _component_Fa = _resolveComponent("Fa")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ImageOverlay, {
      "action-title": _ctx.$t('resources.actions.seeVideo'),
      image: _ctx.thumbnail
    }, null, 8, ["action-title", "image"]),
    _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.resource.title), 1),
    (_ctx.resource.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('academy.scripts.by')) + " " + _toDisplayString(_ctx.resource.user.name), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Fa, { icon: "clock" }),
      _createTextVNode(" " + _toDisplayString(_ctx.duration), 1)
    ])
  ]))
}