import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d3fd19a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex fs-5 mb-6" }
const _hoisted_2 = { class: "fw-bold" }
const _hoisted_3 = { class: "ms-12 text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoViewResource = _resolveComponent("VideoViewResource")!
  const _directive_infinite_scroll_collection = _resolveDirective("infinite-scroll-collection")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slicedTemplates, (template) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `template-${template.id}-scripts`,
        "data-test-class": "template-scripts"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(template.name), 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('academy.scripts.videosCount', [template.mediaResources.count()])), 1),
          (_ctx.preview)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "link-dark ms-auto",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('view-all')))
              }, _toDisplayString(_ctx.$t('academy.actions.viewAll')), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["row cursor-pointer", [_ctx.preview ? 'flex-nowrap overflow-x-auto scrollbar' : 'mb-10']])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(template.mediaResources.all(), (resource) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `script-resource-${resource.id}`,
              class: _normalizeClass(["mb-12", [_ctx.preview ? 'col-auto col-4-half' : 'col-4']])
            }, [
              _createVNode(_component_VideoViewResource, {
                "data-test-id": `template-${template.id}-resources`,
                resource: resource,
                onClick: ($event: any) => (_ctx.goToTemplateScript(template, resource.id))
              }, null, 8, ["data-test-id", "resource", "onClick"])
            ], 2))
          }), 128))
        ], 2)
      ]))
    }), 128))
  ])), [
    [
      _directive_infinite_scroll_collection,
      {collection: _ctx.templates},
      void 0,
      { document: true }
    ]
  ])
}