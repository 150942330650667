
import Template, {Templates} from '@/models/Template';
import {defineComponent, ref} from 'vue';
import {fetchCollectionUntilScrollable, firstScrollingParent} from '@/library/helpers';
import ResourceType from '@/library/enumerations/ResourceType';
import Subject from '@/models/Subject';
import VideoViewResource from '@/components/resources/VideoView.vue';

export default defineComponent({
    components: {
        VideoViewResource,
    },
    props: {
        preview: {
            type: Boolean,
            default: false,
        },
        previewLimit: {
            type: Number,
            default: 1,
        },
    },
    emits: [
        'view-all',
    ],
    setup() {
        return {
            templates: ref<Templates>(new Templates([], {
                filters: {
                    academy: true,
                },
                include: [
                    'subjects.resources.media',
                    'subjects.resources.user',
                ],
            })),
        };
    },
    computed: {
        templatesWithResources(): Templates {
            return this.templates.filter((t: Template) => {
                return t.subjects !== undefined
                    && t.subjects.search((s: Subject) => {
                        return !!s.resources.firstWhere('type', ResourceType.MEDIA);
                    }) !== false;
            });
        },
        slicedTemplates(): Templates {
            return this.preview
                ? this.templatesWithResources.slice(0, this.previewLimit)
                : this.templatesWithResources;
        },
    },
    watch: {
        preview(value: boolean) {
            if (!value) {
                fetchCollectionUntilScrollable(
                    this.templates,
                    firstScrollingParent(this.$el),
                );
            }
        },
    },
    mounted() {
        this.templates.fetch();
    },
    methods: {
        goToTemplateScript(template: Template, resourceId: number) {
            const templateLocation = template.getLocation(
                'script',
                {
                    resourceId,
                },
            );

            this.$router.push(templateLocation);
        },
    },
});
