import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "position-relative mb-12"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Portal = _resolveComponent("Portal")!
  const _component_NavTab = _resolveComponent("NavTab")!
  const _component_Fa = _resolveComponent("Fa")!
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Portal, { to: "navbar-top-content" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('academy.index.title')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_Portal, { to: "navbar-top-append" }, {
      default: _withCtx(() => [
        _createVNode(_component_NavTab, {
          class: "w-100 mt-6",
          tabs: _ctx.tabs,
          onTabClicked: _ctx.setActiveView
        }, null, 8, ["tabs", "onTabClicked"])
      ]),
      _: 1
    }),
    (_ctx.introductionVideo && _ctx.activeView === 'all')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass({'opacity-0': _ctx.showIntroVideo})
          }, [
            _createElementVNode("img", {
              class: "w-100 rounded",
              src: _ctx.introductionVideoThumbnail?.url || _ctx.introductionVideo.thumbnail
            }, null, 8, _hoisted_2),
            _createElementVNode("button", {
              class: "btn btn-dark position-absolute bottom-12 start-12",
              "data-test-id": "btn-show-intro-video",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showIntroVideo = true))
            }, [
              _createVNode(_component_Fa, { icon: "play-solid" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('academy.actions.watchIntroduction')), 1)
            ])
          ], 2),
          (_ctx.showIntroVideo)
            ? (_openBlock(), _createBlock(_component_VideoPlayer, {
                key: 0,
                class: "position-absolute inset-0 text-center",
                "fit-parent": "",
                "play-on-mounted": "",
                "theatre-on-mounted": "",
                video: _ctx.introductionVideo
              }, null, 8, ["video"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (component) => {
      return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(component.component), {
        key: component.key,
        class: "mb-4",
        preview: _ctx.activeView !== component.key,
        visible: _ctx.componentVisible(component.key),
        onViewAll: ($event: any) => (_ctx.activeView = component.key)
      }, null, 8, ["preview", "visible", "onViewAll"])), [
        [_vShow, _ctx.componentVisible(component.key)]
      ])
    }), 128))
  ]))
}