
import Resource, {Resources} from '@/models/Resource';
import {defineComponent, ref} from 'vue';
import {fetchCollectionUntilScrollable, firstScrollingParent} from '@/library/helpers';
import PdfViewer from '@/components/resources/PdfViewer.vue';
import ResourceType from '@/library/enumerations/ResourceType';

export default defineComponent({
    components: {
        PdfViewer,
    },
    props: {
        preview: {
            type: Boolean,
            default: false,
        },
        previewLimit: {
            type: Number,
            default: 3,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    emits: [
        'view-all',
    ],
    setup() {
        return {
            decks: ref<Resources>(new Resources([], {
                filters: {
                    type: ResourceType.MEDIA,
                    mimeType: 'application/pdf',
                },
                include: [
                    'user',
                ],
            })),
        };
    },
    computed: {
        slicedDecks(): Resources {
            return this.preview
                ? this.decks.slice(0, this.previewLimit)
                : this.decks;
        },
    },
    watch: {
        preview(value: boolean) {
            if (!value) {
                fetchCollectionUntilScrollable(
                    this.decks,
                    firstScrollingParent(this.$el),
                );
            }
        },
    },
    mounted() {
        this.decks.fetch();
    },
    methods: {
        goToResource(deck: Resource) {
            this.$router.push(deck.getLocation('show'));
        },
    },
});
