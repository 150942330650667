import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-6 mb-2" }
const _hoisted_2 = {
  key: 1,
  class: "mb-4 text-muted"
}
const _hoisted_3 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Pdf = _resolveComponent("Pdf")!
  const _component_ImageOverlay = _resolveComponent("ImageOverlay")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ImageOverlay, {
      "action-title": _ctx.$t('resources.actions.seeDeck'),
      image: _ctx.thumbnail
    }, _createSlots({ _: 2 }, [
      (!_ctx.thumbnail)
        ? {
            name: "image",
            fn: _withCtx(() => [
              _createVNode(_component_Pdf, {
                page: 1,
                src: _ctx.mediaPdf.url,
                onNumpages: _ctx.setSlides
              }, null, 8, ["src", "onNumpages"])
            ])
          }
        : undefined
    ]), 1032, ["action-title", "image"]),
    (_ctx.thumbnail)
      ? (_openBlock(), _createBlock(_component_Pdf, {
          key: 0,
          class: "d-none",
          page: 1,
          src: _ctx.mediaPdf.url,
          onNumpages: _ctx.setSlides
        }, null, 8, ["src", "onNumpages"]))
      : _createCommentVNode("", true),
    _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.resource.title), 1),
    (_ctx.resource.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('academy.decks.by')) + " " + _toDisplayString(_ctx.resource.user.name), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('resources.view.slidesCount', [_ctx.slides])), 1)
  ]))
}