
import {PropType, defineComponent, ref} from 'vue';
import ImageOverlay from '@/components/common/ImageOverlay.vue';
import Media from '@/models/Media';
import Resource from '@/models/Resource';
import ResourceType from '@/library/enumerations/ResourceType';
import addSeconds from 'date-fns/addSeconds';
import format from 'date-fns/format';

export default defineComponent({
    components: {
        ImageOverlay,
    },
    props: {
        resource: {
            type: Object as PropType<Resource>,
            required: true,
            validate: (r: Resource) => r.type === ResourceType.MEDIA,
        },
    },
    setup(props) {
        const mediaVideo = props.resource.media.firstWhere('mimeType', 'video/mp4');
        const mediaThumbnail = props.resource.media.firstWhere('collectionName', 'thumbnail');

        return {
            mediaVideo: ref<Media>(mediaVideo),
            mediaThumbnail: ref<Media>(mediaThumbnail),
        };
    },
    computed: {
        duration(): string {
            if (!this.mediaVideo.duration) {
                return '-';
            }

            const date = addSeconds(new Date(0), this.mediaVideo.duration);

            return format(date, 'mm:ss');
        },
        thumbnail(): string {
            return this.mediaThumbnail?.getConversion('banner')
                || this.mediaVideo.thumbnail
                || '';
        },
    },
});
