
import {PropType, defineComponent, ref} from 'vue';
import ImageOverlay from '@/components/common/ImageOverlay.vue';
import Media from '@/models/Media';
import Pdf from '@/components/common/Pdf.vue';
import Resource from '@/models/Resource';
import ResourceType from '@/library/enumerations/ResourceType';

export default defineComponent({
    components: {
        ImageOverlay,
        Pdf,
    },
    props: {
        resource: {
            type: Object as PropType<Resource>,
            required: true,
            validate: (r: Resource) => r.type === ResourceType.MEDIA,
        },
    },
    setup(props) {
        const mediaPdf = props.resource.media.firstWhere('mimeType', 'application/pdf');
        const mediaThumbnail = props.resource.media.firstWhere('collectionName', 'thumbnail');

        return {
            mediaPdf: ref<Media>(mediaPdf),
            mediaThumbnail: ref<Media>(mediaThumbnail),
            slides: ref<number>(0),
        };
    },
    computed: {
        thumbnail(): string {
            return this.mediaThumbnail?.getConversion('banner') || '';
        },
    },
    methods: {
        setSlides(pages: number) {
            this.slides = pages;
        },
    },
});
