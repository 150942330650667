import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-flex fs-5 mb-6" }
const _hoisted_2 = { class: "fw-bold" }
const _hoisted_3 = { class: "ms-12 text-muted" }
const _hoisted_4 = {
  ref: "decksContainer",
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfViewer = _resolveComponent("PdfViewer")!
  const _directive_infinite_scroll_collection = _resolveDirective("infinite-scroll-collection")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('academy.decks.title')), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('academy.decks.decksCount', [_ctx.decks.total])), 1),
      (_ctx.preview)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "link-dark ms-auto",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('view-all')))
          }, _toDisplayString(_ctx.$t('academy.actions.viewAll')), 1))
        : _createCommentVNode("", true)
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slicedDecks, (deck) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `deck-${deck.id}`,
          class: "col-4 mb-12"
        }, [
          (_ctx.visible)
            ? (_openBlock(), _createBlock(_component_PdfViewer, {
                key: 0,
                class: "cursor-pointer",
                "data-test-id": `resource-${deck.id}`,
                resource: deck,
                onClick: ($event: any) => (_ctx.goToResource(deck))
              }, null, 8, ["data-test-id", "resource", "onClick"]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])), [
      [
        _directive_infinite_scroll_collection,
        {collection: _ctx.decks},
        void 0,
        { document: true }
      ]
    ])
  ]))
}